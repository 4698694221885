<template>
  <div class="form-valide">
    <error-message type="alert" class="mb-5" :error-list="generalErrors" />
    <div class="row">
      <div class="col-xl-6">
        <input-group
          :required="true"
          :label="translations.gender"
          :error-list="fieldErrors.gender"
        >
          <select
            v-model="form.gender"
            class="form-control default-select"
            :disabled="disabledInputs?.gender"
          >
            <enum-choices
              :default-option="translations.please_select"
              :choices="genderChoices"
            >
            </enum-choices>
          </select>
        </input-group>

        <input-group
          :required="true"
          :label="translations.date_of_birth"
          :error-list="fieldErrors.birthdate"
        >
          <input
            type="text"
            class="form-control"
            v-model="form.birthdate"
            :disabled="disabledInputs?.birthdate"
          />
        </input-group>
        <input-group
          :required="true"
          :label="translations.passport_no"
          :error-list="fieldErrors.passport_no"
        >
          <input type="text" class="form-control" v-model="form.passport_no" />
        </input-group>

        <input-group
          :required="true"
          :label="translations.payment_choice"
          :error-list="fieldErrors.payment_choice"
        >
          <select
            class="form-control default-select"
            v-model="form.payment_choice"
            :disabled="disabledInputs?.payment_choice"
          >
            <enum-choices
              :default-option="translations.please_select"
              :choices="paymentChoices"
            >
            </enum-choices>
          </select>
        </input-group>
        <input-group :label="translations.notes" :error-list="fieldErrors.note">
          <textarea
            rows="5"
            class="form-control"
            v-model="form.note"
            :placeholder="translations.notes_placeholder"
            :disabled="disabledInputs?.note"
          ></textarea>
        </input-group>
      </div>
      <div class="col-xl-6">
        <input-group
          :required="true"
          :label="translations.mother_name"
          :error-list="fieldErrors.mother_name"
        >
          <input type="text" class="form-control" v-model="form.mother_name" />
        </input-group>
        <input-group
          :required="true"
          :label="translations.father_name"
          :error-list="fieldErrors.father_name"
        >
          <input type="text" class="form-control" v-model="form.father_name" />
        </input-group>
        <input-group
          :required="true"
          :label="translations.investment_choice"
          :error-list="fieldErrors.investment_choice"
        >
          <select
            class="form-control default-select"
            v-model="form.investment_choice"
            :disabled="disabledInputs?.investment_choice"
          >
            <enum-choices
              :default-option="translations.please_select"
              :choices="investmentChoices"
            >
            </enum-choices>
          </select>
        </input-group>

        <input-group
          :required="true"
          :label="translations.investment_budget"
          :error-list="fieldErrors.investment_budget"
        >
          <select
            class="form-control default-select"
            v-model="form.investment_budget"
            :disabled="disabledInputs?.investment_budget"
          >
            <enum-choices
              :default-option="translations.please_select"
              :choices="investmentBudgets"
            >
            </enum-choices>
          </select>
        </input-group>

        <input-group
          :label="translations.notes"
          :error-list="fieldErrors.note2"
        >
          <textarea
            rows="5"
            class="form-control"
            v-model="form.note2"
            :placeholder="translations.notes_placeholder"
            :disabled="disabledInputs?.note2"
          ></textarea>
        </input-group>

        <div class="form-group row">
          <div class="col-lg-8 ml-auto">
            <button
              @click="send"
              class="btn btn-primary"
              :class="{ disabled: formLoading }"
            >
              {{ translations.save }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormMixin from "@mixins/Form";
export default {
  mixins: [FormMixin],
  props: [
    "genderChoices",
    "paymentChoices",
    "investmentChoices",
    "investmentBudgets",
  ],
};
</script>